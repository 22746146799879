<script setup>
  import reCaptchaView from './recaptcha'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    isReCAPTCHA: false, // 人机验证是否通过
    isRCShow: true, // 是否展示人机验证
    loading: false
  })

  const formData = reactive({
    name: '',
    phone: '',
    email: '',
    message: ''
  })

  const rules = {

    phone: [
      { required: true, message: 'Please enter your phone number', trigger: 'blur' },
      { pattern: /^[3-9]\d{9}$/, message: 'Invalid phone number. It should not start with 0.', trigger: 'blur' }
    ],
    name: [
      { required: true, message: 'Name field is required', trigger: 'blur' },
      { pattern: /^[a-zA-Z\s]*$/, message: 'Please enter the correct name', trigger: 'blur' },
      { min: 3, max: 25, message: 'Length should be 3 to 25', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter your email address', trigger: 'blur' },
      { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid email address', trigger: 'blur' }
    ],
    message: [
      { required: true, message: 'Please enter a question.', trigger: 'blur' },
      { min: 10, max: 500, message: 'The length is 10 to 500', trigger: 'blur' }
    ]
  }

  const ruleFormRef = ref()

  // 随机方法
  const randomShowReCAPTCHA = () => {
    // 生成一个0到1之间的随机数
    const randomValue = Math.random()

    // 设置展示人机验证的概率，例如50%
    // if (randomValue < 0.5) {
    //   data.isRCShow = true // 随机展示人机验证
    //   data.isReCAPTCHA = false // 默认设置为未通过
    // } else {
    //   data.isRCShow = false // 不展示人机验证
    //   data.isReCAPTCHA = true // 默认设置为通过
    // }
    data.isRCShow = true // 随机展示人机验证
    data.isReCAPTCHA = false // 默认设置为未通过
  }

  // 人机验证通过
  const reCaptchaVerify = (token) => {
    console.log(token, 312312321)
    if (token) {
      api.common.getCaptchaVerify(token).then((res) => {
        console.log(res, '人机验证')
        if (res.success) {
          data.isReCAPTCHA = true
        } else {
          data.isReCAPTCHA = false
        }
      })
    }
  }

  // 人机验证加载失败
  const reCaptchaFail = () => {
    data.isRCShow = false // 不展示人机验证
    data.isReCAPTCHA = true // 默认设置为通过
  }

  // 点击提交
  const handleSend = () => {
    ruleFormRef.value.validate((valid, fields) => {
      if (valid) {
        if (data.isRCShow && !data.isReCAPTCHA) {
          return proxy.$toast('Please verify that you are not a robot', { type: 'error' })
        }
        console.log('验证通过', formData)
        getServicesContacts()
      }
    })
  }

  // 提交客服服务
  const getServicesContacts = () => {
    if (data.loading) return
    data.loading = true
    api.activity.getServicesContacts(formData).then((res) => {
      data.loading = false
      if (res.status) {
        resetForm()
        sendSuc()
        // proxy.$toast(`Message Sent Successfully.`, { type: 'success' })
      } else {
        proxy.$toast(`${res.data.message}`, { type: 'error' })
      }
    })
  }

  // 提交成功
  const sendSuc = () => {
    proxy.$Message.confirm({
      type: 'succeed',
      title: 'Thank you for getting in touch!',
      msg: `<div class='mb20'>
        Our team is currently reviewing your message and will get beck to you as soon as possible. 
        <br/> 
        <p style='margin-top: 8px'>In the mean time you can check out our hot selling products.</p>
        </div>
      `,
      isCancel: false,
      okText: 'CONTINUE SHOPPING',
      onOk: () => {
        proxy.$router.push({
          path: '/'
        })
      }
    })
  }

  // 重置方法
  const resetForm = () => {
    formData.name = ''
    formData.phone = ''
    formData.email = ''
    formData.message = ''
    randomShowReCAPTCHA()
  }

  onMounted(() => {
    // randomShowReCAPTCHA()
  })
</script>

<template>
  <div class="page-conetent">
    <div class="warp-box">
      <JLImg src="https://obs.pricoo.pk/fddc84cf.png" class="banner"></JLImg>
      <div class="form-box" v-loading="data.loading">
        <p class="title">Get In Touch</p>
        <p class="sub-title">Our customer care team is always ready for you.</p>

        <el-form class="form-content-box rowSS" ref="ruleFormRef" :model="formData" :rules="rules" label-width="auto" label-position="top">
          <div class="left-box">
            <el-form-item prop="name" label="Name" class="form-input-item">
              <el-input size="large" v-model="formData.name" maxlength="40" placeholder="Enter Name" clearable> </el-input>
            </el-form-item>
            <el-form-item prop="phone" label="Phone Number" class="form-input-item">
              <el-input
                class="phone-input"
                size="large"
                v-model="formData.phone"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="10"
                placeholder="Enter Phone Number"
                clearable
              >
                <template #prepend> +92 </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="email" label="Email" class="form-input-item">
              <el-input size="large" v-model="formData.email" maxlength="40" placeholder="Enter Email" clearable> </el-input>
            </el-form-item>
          </div>
          <div class="ml20 right-box">
            <el-form-item prop="message" label="Message" class="form-input-item">
              <el-input clear="message-input" size="large" type="textarea" :rows="10" v-model="formData.message" maxlength="500" placeholder="Type your question here!" clearable>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <reCaptcha-view v-if="data.isRCShow" sitekey="6LdARV4qAAAAANGLAVfauvwLJMzj4BcFzg8qi5Fh" hl="en" @verify="reCaptchaVerify" @fail="reCaptchaFail"></reCaptcha-view>
        <el-button class="login-btn primary-btn" size="large" @click="handleSend"> SEND </el-button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  /* phone-input 类下的 el-input__wrapper */
  :deep(.phone-input .el-input__wrapper) {
    border-radius: 0px 6px 6px 0px !important;
  }

  .page-conetent {
    height: 100%;
    width: 100%;
    .warp-box {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      .banner {
        width: 100%;
        height: 400px;
      }
      .form-box {
        display: flex;
        flex-direction: column;
        width: 1000px;
        margin: 32px auto;
        padding: 32px 40px;
        color: #303133;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 0px 12px 0px rgba(83, 164, 219, 0.12);
        .title {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
        .sub-title {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  .form-content-box {
    margin-top: 32px;
    gap: 20px;
    .left-box,
    .right-box {
      flex: 1;
    }
  }
  .login-btn {
    width: 268px;
    height: 48px;
    margin-top: 24px;
    margin-left: auto;
    color: rgba(255, 255, 255, 1);
    background-color: var(--btnBgColor);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border: none;
    outline: none;
  }
</style>
